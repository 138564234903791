import React from 'react';
import { Toolbar, SaveButton, DeleteButton, Edit, SimpleForm, TextInput, SelectInput, PasswordInput, required, email, minLength, maxLength } from 'react-admin';

const validateEmail = email();
const validateRole = required();
const validatePassword = [required(), minLength(5), maxLength(20)];

const UserEditToolbar = props => {
    const { record } = props;
    return (<Toolbar {...props} >
        <SaveButton />
        <DeleteButton mutationMode="pessimistic" confirmTitle={`Delete user ${record.username}`}/>
    </Toolbar>)
};

export const userEdit = props => {
    return (<Edit title="Edit user data" label="Edit user data" {...props}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput label="Username" source="username" disabled />
            <TextInput label="Name" source="name" />
            <TextInput label="Email" source="email" validate={validateEmail} />
            <SelectInput label="Role" source="role" choices={[
                { id: 'Admin', name: 'Admin' },
                { id: 'User', name: 'User' },
            ]} validate={validateRole} />
            <PasswordInput label="Password" source="password" validate={validatePassword}/>
        </SimpleForm>
    </Edit>);
};

export default userEdit;