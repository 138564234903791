import React from 'react';
import { Admin, Resource } from 'react-admin';
import authProvider from './providers/authProvider';
import dataProviderUser from './providers/dataProvider';

import darkTheme from './themes/darkTheme';
import customersConfiguration from './components/customers';
import usersConfiguration from './components/users';
import logsConfiguration from './components/logs';
import errorLogsConfiguration from './components/errorlogs';
import eventLogsConfiguration from './components/eventlogs';

import storagesConfiguration from './components/storages';

const dataProvider = dataProviderUser();
const App = () => (
  <Admin authProvider={authProvider} dataProvider={dataProvider} theme={darkTheme}>
      {permissions => [
      <Resource name="customer" options={{ label: 'Customers' }} {...customersConfiguration} />, 
          permissions === 'Admin' ? <Resource name="user" options={{ label: 'Users' }} {...usersConfiguration} /> : null,
          permissions === 'Admin' ? <Resource name="log" options={{ label: 'Logs' }} {...logsConfiguration} /> : null,
          permissions === 'Admin' ? <Resource name="errorLog" options={{ label: 'Error Logs' }} {...errorLogsConfiguration} /> : null,
          permissions === 'Admin' ? <Resource name="eventLog" options={{ label: 'Event Logs' }} {...eventLogsConfiguration} /> : null,
          permissions === 'Admin' ? <Resource name="storage" options={{ label: 'Storage' }} {...storagesConfiguration} /> : null,
      ]}
  </Admin>
);

export default App;