import UserIcon from '@material-ui/icons/SupervisedUserCircle';
import UserList from './userList.js';
import UserCreate from './userCreate.js';
import UserEdit from './userEdit.js';

export default {
    icon: UserIcon,
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
};