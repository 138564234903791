import React from 'react';
import { Create, SimpleForm, NumberInput, TextField, TextInput, Toolbar, required, number } from 'react-admin';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import SaveWithConfirmButton from './saveWithConfirmButton.js';

const validatePoints = [required(), number()];
const validateReason = required();

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const AddPointToolbar = props => {
  const location = useLocation();
  const addRemove =
    location.state && location.state.record
      ? location.state.record.addRemove
      : undefined;
  return(<Toolbar {...props} classes={useStyles()}>
    <SaveWithConfirmButton label={addRemove ? 'Add points' : 'Remove points'} title={addRemove ? 'Add points' : 'Remove points'} resource="customer" />
  </Toolbar>)
};

export const customerCreate = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const id =
      location.state && location.state.record
        ? location.state.record.merchant_id
        : undefined;
    const email =
      location.state && location.state.record
        ? location.state.record.email
        : undefined;
    const name =
      location.state && location.state.record
        ? location.state.record.name
        : undefined;
    const addRemove =
      location.state && location.state.record
        ? location.state.record.addRemove
        : undefined;

    return (<Create label={addRemove ? 'Add points' : 'Remove points'} title={addRemove ? 'Add points' : 'Remove points'} {...props}>
        <SimpleForm toolbar={<AddPointToolbar />}>
            <TextInput hidden source="id" defaultValue={id} />
            <TextInput hidden source="email" defaultValue={email} />
            <TextInput hidden source="name" defaultValue={name} />
            <TextInput hidden source="addRemove" defaultValue={addRemove} />
            <TextField addLabel={false} source="name" align="center" component="p" variant="h6" gutterBottom/>
            <NumberInput label="Points" source="points" validate={validatePoints} />
            <TextInput label="Reason" multiline source="reason" validate={validateReason} />
        </SimpleForm>
    </Create>);
};

export default customerCreate;