import inMemoryJWT from './inMemoryJWT/inMemoryJWTManager';

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request('auth/authenticate', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint('auth/refresh-token');
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                return inMemoryJWT.setToken(token);
            });
    },

    logout: () => {
        const request = new Request('auth/logout', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.ereaseToken();

        return fetch(request).then(() => '/login');
    },

    checkAuth: () => {
        const token = inMemoryJWT.getToken();
        if (token) {
          return Promise.resolve();
        } else {
          return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                return Promise.resolve();
            }
            return Promise.reject();
          });
        }
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve(
                inMemoryJWT.getRole()
            ) : Promise.reject();
        });
    },

    getIdentity: () => {
         return inMemoryJWT.waitForTokenRefresh().then(() => {
             return inMemoryJWT.getToken() ? Promise.resolve({
                 id : inMemoryJWT.getUserId(), 
                 fullName: inMemoryJWT.getUserName()
             }) : Promise.reject();
         });
    }
};

export default authProvider; 