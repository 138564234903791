import decodeJwt from "jwt-decode"

const inMemoryJWTManager = () => {
    let inMemoryToken = null;
    let inMemoryUserId = null;
    let inMemoryUserName = null;
    let inMemoryRole = null;
    let isRefreshing = null;
    let logoutEventName = 'dj-logout';
    let refreshEndpoint = 'auth/refresh-token';
    let refreshTimeOutId;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const  getRefreshedToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    return { token: null };
                }
                return response.json();
            })
            .then(({token}) => {
                if (token) {
                    setToken(token);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const getToken = () => inMemoryToken;

    const getUserId = () => inMemoryUserId;

    const getUserName = () => inMemoryUserName;

    const getRole = () => inMemoryRole;

    const setToken = (token) => {
        inMemoryToken = token;
        var decoded = decodeJwt(token);
        inMemoryUserId = decoded.nameid;
        inMemoryUserName = decoded.name;
        inMemoryRole = decoded.role;
        refreshToken(decoded.exp - decoded.iat);
        return true;
    };

    const ereaseToken = () => {
        inMemoryToken = null;
        inMemoryUserId = null;
        inMemoryUserName = null;
        inMemoryRole = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryToken = null;
            inMemoryUserId = null;
            inMemoryUserName = null;
            inMemoryRole = null;
        }
    });

    return {
        ereaseToken,
        getRefreshedToken,
        getToken,
        getUserId,
        getUserName,
        getRole,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default inMemoryJWTManager();