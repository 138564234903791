import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, PasswordInput, required, email, minLength, maxLength } from 'react-admin';

const validateUsername = [required(), minLength(5), maxLength(20)];
const validateEmail = email();
const validateRole = required();
const validatePassword = [required(), minLength(5), maxLength(20)];

export const userCreate = props => {
    return (<Create title="Create user" label="Create user" {...props}>
        <SimpleForm>
            <TextInput label="Username" source="username" validate={validateUsername}/>
            <TextInput label="Name" source="name" />
            <TextInput label="Email" source="email" validate={validateEmail} />
            <SelectInput label="Role" source="role" choices={[
                { id: 'Admin', name: 'Admin' },
                { id: 'User', name: 'User' },
            ]} validate={validateRole} />
            <PasswordInput label="Password" source="password" validate={validatePassword}/>
        </SimpleForm>
    </Create>);
};

export default userCreate;