import { stringify } from 'query-string';
import { fetchUtils } from 'ra-core';

import inMemoryJWT from './inMemoryJWT/inMemoryJWTManager';

const getXTotalCountHeaderValue = (headers) => {
    if (!headers.has('x-total-count')) {
        throw new Error(
            'The X-Total-Count header is missing in the HTTP Response.'
        );
    }

    return parseInt(headers.get('x-total-count'), 10);
};

export default (apiUrl) => {
    const httpClient = async (url, options = {}) => {
        if (!options.headers) {
            options.headers = new Headers({
                Accept: 'application/json'
            });
        }
        
        const token = inMemoryJWT.getToken();

        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
            return fetchUtils.fetchJson(url, options);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint('auth/refresh-token');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
                };                  
                return fetchUtils.fetchJson(url, options);
            });
        }
    };

    return {
        getList: async (resource, params) => {
            const { page: currentPage, perPage } = params.pagination;
            const { field, order } = params.sort;
            const filter = params.filter.email;
            const query = {
                sortBy: field,
                orderBy: order,
                currentPage,
                perPage,
                filter
            };

            const url = `${resource}?${stringify(query)}`;

            const { headers, json } = await httpClient(url);
            return {
                data: json,
                total: getXTotalCountHeaderValue(headers)
            };
        },

        getOne: async (resource, params) => {
            const url = `${resource}/${params.id}`;

            const { json } = await httpClient(url);
            return {
                data: json
            };
        },

        getMany: async (resource, params) => {
            const url = `${resource}`;

            const { json } = await httpClient(url);
            return {
                data: json
            };
        },

        getManyReference: (resource, params) => {
            const url = `${resource}`;

            return httpClient(url).then(({ json }) => {
                return {
                    data: json,
                };
            });
        },

        update: async (resource, params) => {
            const url = `${resource}`;

            const { json } = await httpClient(url, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            });
            return {
                data: json,
            };
        },

        updateMany: () => Promise.reject(),

        create: async (resource, params) => {
            const url = `${resource}`;

            const { json } = await httpClient(url, {
                method: 'POST',
                body: JSON.stringify(params.data),
            });
            return {
                data: json,
            };
        },

        delete: async (resource, params) => {
            const url = `${resource}/${params.id}`;

            const { json } = await httpClient(url, {
                method: 'DELETE',
            });
            return {
                data: json
            };
        },

        deleteMany: () => Promise.reject(),
    };
};