import React from 'react';
import { Datagrid, TextField, DateField, List } from 'react-admin';
import RunButton from './runButton';

export const storageList = props => {
    return (<List actions={false} empty={false} bulkActionButtons={false} {...props} sort={{ field: 'lastWriteTime', order: 'DESC' }}>
        <Datagrid>
            <TextField label="Name" source="name" />
            <RunButton title="Manual execution" label="Manual execution" />
            <TextField label="Size(bytes)" source="size" />
            <DateField label="Created at" source="lastWriteTime" showTime />
        </Datagrid>
    </List>);
};

export default storageList;