import React from 'react';
import { Datagrid, TextField, DateField, List } from 'react-admin';

export const eventLogList = props => {
    return (<List empty={false} bulkActionButtons={false} {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField label="Name" source="name" />
            <TextField label="Message" source="message" />
            <DateField label="Created at" source="createdAt" showTime />
        </Datagrid>
    </List>);
};

export default eventLogList;