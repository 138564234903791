import React, { Fragment, useState} from 'react';
import { Confirm, SaveButton } from 'react-admin';
import { useLocation } from 'react-router';
import { useFormState } from "react-final-form";

const SaveWithConfirmButton = ({ handleSubmitWithRedirect, handleSubmit, ...props }) => {
    const location = useLocation();
    const name =
      location.state && location.state.record
        ? location.state.record.name
        : undefined;
    const addRemove =
      location.state && location.state.record
        ? location.state.record.addRemove
        : undefined;

    const {values, valid} = useFormState();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        if (valid)
        {
            setOpen(true);
        }
    }
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        handleSubmit();
        setOpen(false);
    };

    return (
    <Fragment>
        <SaveButton {...props} handleSubmitWithRedirect={handleClick} handleSubmit={handleClick} />
        <Confirm
            isOpen={open}
            title="Proceed?"
            content={"You are" + (addRemove ? " ADDING " + values.points + " points to " : " REMOVING " + values.points + " points from ") + name}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
            confirm="Yes"
            cancel="No"
        />
    </Fragment>);
}

export default SaveWithConfirmButton;
