import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/AddCircleTwoTone';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  }));

const AddPointsButton = ({ record }) => {
    const classes = useStyles();
  
    return (
      <Button className={classes.button} 
            variant="contained"
            component={Link}
            to={{
                pathname: '/customer/create',
                state: { record: { merchant_id: record.merchantId, email: record.email, name: record.name, addRemove: true } }
            }}>
        <ChatBubbleIcon />
      </Button>
    );
};

export default AddPointsButton;