import React from 'react';
import { Create, SimpleForm, TextField, Toolbar } from 'react-admin';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import RunWithConfirmButton from './runWithConfirmButton.js';

const useStyles = makeStyles({
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
});

const ManualStartToolbar = props => {
  return(<Toolbar {...props} classes={useStyles()}>
    <RunWithConfirmButton label="Manual start" title="Manual start" resource="storage" />
  </Toolbar>)
};

export const storageCreate = props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const location = useLocation();
    const name =
      location.state && location.state.record
        ? location.state.record.name
        : undefined;

    return (<Create label="Manual start" title="Manual start" {...props}>
        <SimpleForm toolbar={<ManualStartToolbar />}>
            <TextField addLabel={false} source="name" align="center" component="p" variant="h6" gutterBottom/>
        </SimpleForm>
    </Create>);
};

export default storageCreate;