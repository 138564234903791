import React from 'react';
import { Filter, FunctionField, TextInput, Datagrid, TextField, List } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AddPointsButton from './addPointButton';
import RemovePointsButton from './removePointButton';
import classnames from 'classnames';

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="email" defaultValue="" alwaysOn />
    </Filter>
);

const useStyles = makeStyles({
    tierBasic: { color: 'white', backgroundColor: 'black', padding: 8 },
    tierSilver: { color: 'blakc', backgroundColor: '#CCCCCC', padding: 8 },
    tierGold: { color: 'black', backgroundColor: '#E8C136', padding: 8 },
    tierPlatinum: { color: 'black', backgroundColor: '#9ECBD4', padding: 8 }
});

const TierFunctionField = props => {
    const classes = useStyles();
    return <FunctionField align="center" className={classnames({
        [classes.tierBasic]: props.record[props.source] === 'Basic',
        [classes.tierSilver]: props.record[props.source] === 'Silver',
        [classes.tierGold]: props.record[props.source] === 'Gold',
        [classes.tierPlatinum]: props.record[props.source] === 'Platinum',
    })} 
    {...props} />;
};

export const customerList = ({ permissions, ...props }) => {
    return (<List actions={false} empty={false} bulkActionButtons={false} filters={<PostFilter />} {...props}>
        <Datagrid>
            <TextField label="Name" source="name" sortable={false} />
            <TextField label="Email" source="email" sortable={false}/>
            <TextField label="Points approved" source="pointsApproved" sortable={false}/>
            <TextField label="Points pending" source="pointsPending" sortable={false}/>
            <TextField label="Points spent" source="pointsSpent" sortable={false}/>
            <TierFunctionField label="Tier" source="tier" render={record => `${record.tier} ${record.discount}`} />
            {permissions === 'Admin' && <AddPointsButton title="Add Points" label="Add Points" />}
            {permissions === 'Admin' && <RemovePointsButton title="Remove Points" label="Remove Points" />}
        </Datagrid>
    </List>);
};

export default customerList;