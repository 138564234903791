import React from 'react';
import { Datagrid, TextField, DateField, List, EditButton } from 'react-admin';

export const userList = props => {
    return (<List bulkActionButtons={false} {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
        <Datagrid>
            <TextField label="Username" source="username" />
            <TextField label="Name" source="name" />
            <TextField label="Email" source="email" />
            <TextField label="Role" source="role" />
            <DateField label="Created at" source="createdAt" showTime />
            <EditButton/>
        </Datagrid>
    </List>);
};

export default userList;